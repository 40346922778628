.RestaurantOffer-left-grid,
.RestaurantOffer-right-grid {
    position: relative;
}

.RestaurantOffer-right-grid::before {
    border: .7px solid rgba(0, 0, 0, .06);
    content: "";
    height: 100%;
    left: -1.5rem;
    position: absolute;
    top: 0;
    width: 0;
}

.RestaurantOffer-area-option {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.RestaurantOffer__cta-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2rem;
}

.RestaurantOffer__totalCapacity {
    border-top: .7px solid rgba(0, 0, 0, .06);
    font-family: "Roboto", sans-serif !important;
    font-size: 2rem;
    font-weight: 100;
    text-align: end;

    p>span {
        font-family: "CommutersSans", sans-serif !important;
        font-size: 2.7rem;
    }
}

.info-message-container {
    color: #184371;
    font-size: .85rem;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    background-color: #184371;
    font-size: .75rem;
}

.MuiSvgIcon-root.info-icon-tooltip {
    cursor: pointer;
    font-size: 1.2rem;
}

@media only screen and (max-width: 600px) {
    .RestaurantOffer-right-grid {
        padding-left: 1rem;
        padding-top: 2rem;
    }

    .RestaurantOffer__right-grid::before {
        border: 0;
        content: "";
        height: 0;
    }

    .RestaurantOffer-form-container {
        margin-right: 0 !important;
    }

   .textoContador {
        color: #bfbfbf;
        padding-left: 20px;

        padding-right: 20px;
    }
}
.rmdp-input-container {
    width: 100%;
}
.rmdp-input{
    padding: 20px 10px;
    width: 100%;

  
}
.rmdp-container {
    width: 100%;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #000;
    box-shadow: 0 0 3px #8798ad;
    color: #fff;
}
.rmdp-week-day{
    color: #000;
}
.rmdp-day.rmdp-today span {
    background-color: #919191;
    color: #fff;
}
.MuiTab-root {
  
    max-width: 15%;
    min-width: 150px !important;
}
.mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}