.dialog-base-modal {
  background: #fff;
  box-shadow: 0 0.4375rem 4rem 0 rgba(0, 0, 0, 0.07);
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 28rem;
}

.dialog-base-modal__overlay {
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5));
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 30000;
}
.title {
    font-family: "CommutersSans";
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
.dialog-base-modal__content {
  padding: 1.5rem;
}

.dialog-base-modal__title {
  text-transform: uppercase;
}


.dialog-base-modal__cta-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2rem;
}

@media only screen and (max-width: 600px) {
  .dialog-base-modal {
    max-width: 90%;
  }
}


.blackbtn {
  background-color: var(--black);
  height: 40px;
  margin: 109px 0 0 27px;
  padding: 10px 32px;
  width: 148px;
}

.flexend {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.dFlex {
  display: flex;
}

.w100 {
  width: 100%;
}

.spaceBetween {
  justify-content: space-between;

}

.cancelBtn {
  margin-right: 20px !important;
}

.titleLabel {
  border-radius: 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-family: CommutersSans;
  font-size: .9rem;
  font-weight: 500;
  line-height: 1.75;
  min-width: 64px;
  text-transform: uppercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiDialog-root {
  z-index: 999999 !important;
}

.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  width: 100% !important;
}

.warningTitle {
    color: var(--black);
    font-family: CommutersSans;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
  }
  .warningDisclaimer {
        color: var(--black);
        font-family: "Roboto", sans-serif !important;
        font-size: 18px;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        margin-top:20px;
        text-align: left;
      }