.staff {
    display: flex;
    flex-direction: column;
    position: relative;
}

.staff__header {
    border-bottom: .05rem solid rgba(0, 0, 0, .1);
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.staff__tabs-container {
    max-width: 100%;
    width: 100%;
}

.staff-table__box {
    max-width: 100%;
    overflow-x: scroll;
}

.staff__searchBar {
    background-color: #fff;
    padding: .2rem .5rem;
}

.staff-table__box {
    overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
    .staff__header {
        border-bottom: .3rem solid #000;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    .staff__tabs-container {
        margin-top: 0 !important;
        padding: 1rem 0 !important;
    }
}