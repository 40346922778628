.clients {
    display: flex;
    flex-direction: column;
    position: relative;
}

.clients__header {
    padding-top: 2rem;
}

.clients__tabs-container {
    max-width: 100%;
    width: 100%;
}

.clients-table__box {
    max-width: 100%;
    overflow-x: scroll;
}

.clients__searchBar {
    background-color: #fff;
    padding: .2rem .5rem;
}

.clients-table__box {
    overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
    .clients__header {
        border-bottom: .3rem solid #000;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    .clients__tabs-container {
        margin-top: 0 !important;
        padding: 1rem 0 !important;
    }
}