.floormap-icon2{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.2rem !important;
  height: 2.936rem;
  padding: 0.5rem !important;
  width: 6.5rem;
}

.text-tiny2{
  font-size: 0.6rem !important;
}

.encima{
  background-color: #fff;
  position: absolute;
  width: 20rem;
  z-index: 3;
}

.cubrir{
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.color-slider{
  position: relative;
}

.alpha-slider{
  border: .7px solid rgba(0, 0, 0, 0.521) !important;
  border-radius: 3px;
  margin-top: 14px;
  position: relative;
}

.line-image{
  width: 100%;
}

.container-line-image{
  width: 7rem !important;
}

.container-letra-image{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 7rem !important;
}