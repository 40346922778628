  .dialog-logout-modal {
    background: #fff;
    box-shadow: 0 0.4375rem 4rem 0 rgba(0, 0, 0, 0.07);
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 28rem;
  }

  .dialog-logout-modal__overlay {
    background-blend-mode: multiply;
    background-image: linear-gradient(
      to bottom,
      rgba(0,0,0,0.5),
      rgba(0,0,0,0.5)
    );
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: 30000;
  }

  .dialog-logout-modal__content {
    padding: 1.5rem;
  }

  

  .dialog-logout-modal__cta-container {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 2rem;
    }

    @media only screen and (max-width: 600px) {
     .dialog-logout-modal {
        max-width: 90%;
      }
    }


