.charges {
    display: flex;
    flex-direction: column;
    position: relative;
}

.charges__header {
    border-bottom: .05rem solid rgba(0, 0, 0, 0.2);
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.charges__title {
    text-transform: uppercase;
}

.charges__tabs-container {
    max-width: 100%;
    width: 100%;
}

.charges-table__box {
    max-width: 100%;
    overflow-x: scroll;
}

.charges__searchBar {
    background-color: #fff;
    padding: .2rem .5rem;
}

.charges__change-month-mobile {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-transform: uppercase;
    width: 100%;
}

.charges-table__box {
        overflow-x: hidden;

}

@media only screen and (max-width: 600px) {
    .charges__header {
        border-bottom: .3rem solid #000;
    }
    .charges__header:nth-child(3) {
        border-bottom: .05rem solid rgba(0, 0, 0, 0.2);
    }
    .charges__tabs-container {
        margin-top: 0 !important;
        padding: 1rem 0 !important;
    }
}