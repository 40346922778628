@import "/src/styles/import_once";
@include import-once("layouts/staff-edit") {
    .staff-edit {
        display: flex;
        margin: 0 0 2.5rem;
        position: relative;
    }
    .staff-edit__header {
        border-bottom: solid 0.5px rgba(0, 0, 0, .2);
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        padding-top: 2rem;
    }
    .staff-edit-form__container {
        width: 100%;
    }
    .staff-edit-form__field-container {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1rem 0;
        padding-bottom: .5rem;
        width: 100%;
    }
    .staff-edit-form__buttons-group {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        .staff-edit-form__button-create {
          padding-left: 2rem !important;
          padding-right: 2rem !important;
        }
    }
    @media only screen and (max-width: 600px) {
        .staff-edit {
            margin: 1rem 0 4rem;
        }
        .staff-edit__header {
            border-bottom: .3rem solid #000;
            padding-bottom: 1rem;
            padding-top: 1rem;
        }
        .staff-edit-form__field-container {
            flex-direction: column;
            justify-content: flex-start;
        }
        .staff-edit-form__container {
            margin-bottom: 2.5rem;
        }
    }
}
