.w90{
  width: 90%;
}

.mb-1e{
  margin-bottom: 1rem !important;
}

.floormap-item-input{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.2rem !important;
  height: 2.1rem;
  padding: 0.1rem 0.4rem !important;
  width: 2.9rem;

  &__value{
    border: 0 !important;
    font-size: 0.98rem !important;
    font-weight: bold !important;
    padding: 0.25rem 0.4rem !important;
  }
}

.tiny-font{
  font-size: 0.7rem !important;
  font-weight: bold !important;
}

/* stylelint-disable property-no-vendor-prefix */
.floormap-item-input__value::-webkit-inner-spin-button,
.floormap-item-input__value::-webkit-outer-spin-button{
  -webkit-appearance: none !important;
  margin: 0; 
}

.floormap-item-input__value {
  -moz-appearance: textfield !important;
  margin: 0;
}

.border-select{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.3rem !important;
}

.pading-item-select{
  padding: 0.2rem 0.25rem !important;
}

.w110{
  width: 100%;
}