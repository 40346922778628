  .dialog-edit-restaurant-cost-modal {
      background: #fff;
      box-shadow: 0 0.4375rem 4rem 0 rgba(0, 0, 0, 0.07);
      left: 50%;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 28rem;
  }
  
  .dialog-edit-restaurant-cost-modal__overlay {
      background-blend-mode: multiply;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      height: 100vh;
      position: fixed;
      width: 100vw;
      z-index: 30000;
  }
  
  .dialog-edit-restaurant-cost-modal__body {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin: 2rem 0 0;
      .MuiRating-root {
          font-size: 2.5rem !important;
      }
  }
  
  .dialog-edit-restaurant-cost-modal__content {
      padding: 1.5rem;
      .MuiRating-iconFilled {
          color: #000;
      }
      .MuiRating-iHover {
          color: #000;
      }
  }
  
  .dialog-edit-restaurant-cost-modal__title {
      text-transform: uppercase;
  }
  
  .dialog-edit-restaurant-cost-modal__cta-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 2rem;
  }
  .customselect{
        margin-bottom: 1rem;
        padding: 10px;
        width: 100%;

  }
  
  @media only screen and (max-width: 600px) {
      .dialog-edit-restaurant-cost-modal {
          max-width: 90%;
      }
  }