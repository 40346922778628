.restaurantSchedule-left-grid,
.restaurantSchedule-right-grid {
    position: relative;
}

.restaurantSchedule-right-grid::before {
    border: .7px solid rgba(0, 0, 0, .06);
    content: "";
    height: 100%;
   // left: -1.5rem;
    position: absolute;
    top: 0;
    width: 0;
}
.scroll-container {
    display: flex; /* Para que los hijos se coloquen en una fila */
    overflow-x: auto; /* Habilita el scroll horizontal */
    padding: 10px; /* Espaciado */
    white-space: nowrap; /* Evita que los hijos se envuelvan */
  }
.restaurantSchedule-area-option {
    align-items: center;
    border-bottom: 1.5px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.restaurantSchedule__cta-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2rem;
}

.restaurant-schedule__time-picker .MuiInput-underline::before,
.restaurant-schedule__time-picker .MuiInput-underline::after {
    border: 0 !important;
}

.info-message-container {
      color: #184371;
      font-size: .85rem;
}

.column33 {
    max-width: 300px;  /* Ancho máximo para evitar que se expanda demasiado */
    min-width: 150px;  /* Ancho mínimo para evitar que se vuelva demasiado estrecho */
    overflow: hidden;   /* Oculta el contenido que excede el tamaño del contenedor */
    text-overflow: ellipsis; /* Muestra "..." si el texto es demasiado largo */
    white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    width: 20%; /* Ancho fijo en porcentaje */


}
.restaurantSchedule-area-option {
  display: flex; /* Usa flexbox para alinear horizontalmente */
 // flex-wrap: wrap-reverse; /* Evita que los elementos se envuelvan */
  overflow-x: auto; /* Habilita el desplazamiento horizontal */
  width: 100%; /* Asegúrate de que ocupe todo el ancho disponible */
}
.column662 {
    width: 66.6%;
}
.w502 {
    width: 50%;
}
.mr-22 {
    margin-right: -7rem;
    padding-left: 53px;
  }

.column662 {
  flex: 1; /* Permite que las columnas tomen el espacio disponible */
}

.w502 {
  flex: 0 0 15%; /* Cada columna ocupa el 50% del espacio disponible */
}

@media only screen and (max-width: 600px) {
    .MuiButton-disableElevation {
        margin-top: 0 !important;
    }
    .MuiButton-containedSecondary {
        margin-top: 5px !important;
    }
    .restaurantSchedule-right-grid {
      //  padding-left: 1rem;
        padding-top: 2rem;
    }
    .restaurantSchedule__right-grid::before {
        border: 0;
        content: "";
        height: 0;
    }
    .restaurantSchedule-area-option {
    align-items: flex-start;
    }
    .dashedBorderLeft{
       // border-left: unset !important;
        padding-left:0 !important;
    }

}

.dflex{
    display: flex;
}
.textCenter{
    justify-content: center;
    padding-bottom:10px;
    text-align: center;

}
.dashedBorderLeft{
   // border-left: 2px dashed #000;
  //  padding-left:10px;
}
.MuiButton-disableElevation {
    margin-top: 0 !important;
}
.margintopfix{
    margin-top:5px !important;
}
