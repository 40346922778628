.address-suggestions {
    background-color: #fff;
    box-shadow: 0 0.4375rem 4rem 0 rgba(0, 0, 0, 0.2);
    margin: 0;
    max-height: 6rem;
    overflow-y: auto;
    position: absolute;
    top: calc(100%);
    z-index: 999;
}

.address-suggestion {
    list-style: none;
    margin: 0;
    padding: 0
}

.address-suggestion__button {
    background-color: var(--white-100);
    border: unset;
    border-bottom: 1px solid var(--black-07);
    font-size: 0.8rem;
    margin-bottom: 0;
    padding: 1rem;
    text-align: left;
    width: 100%;
    &:hover {
        background-color: var(--black-07);
        cursor: pointer;
    }
}
