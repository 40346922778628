@import "/src/styles/import_once";
@include import-once("layouts/offers-edit") {
    .offers-edit {
        display: flex;
        margin: 0 0 2.5rem;
        position: relative;
    }
    .offers-edit__header {
        border-bottom: solid 0.5px rgba(0, 0, 0, .2);
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        padding-top: 2rem;
    }
    .offers-edit-form__container {
        width: 100%;
    }
    .offers-edit-form__field-container {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1rem 0;
        padding-bottom: .5rem;
        width: 100%;
    }
    .offers-edit-form__buttons-group {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        .offers-edit-form__button-create {
          padding-left: 2rem !important;
          padding-right: 2rem !important;
        }
    }
    @media only screen and (max-width: 600px) {
        .offers-edit {
            margin: 1rem 0 4rem;
        }
        .offers-edit__header {
            border-bottom: .3rem solid #000;
            padding-bottom: 1rem;
            padding-top: 1rem;
        }
        .offers-edit-form__field-container {
            flex-direction: column;
            justify-content: flex-start;
        }
        .offers-edit-form__container {
            margin-bottom: 2.5rem;
        }
    }
}
.rmdp-day.rmdp-today span {
    background-color: #5b5b5b !important;
    color: #fff;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #000 !important;
    box-shadow: 0 0 3px #000 !important;
    color: #fff;
}
.rmdp-week-day {
    color: #000 !important;
    cursor: default;
    font-size: 13px;
    font-weight: 500;
}
.rmdp-input {
    padding: 20px 10px !important;
    width: 100%;
}