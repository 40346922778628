/* eslint-disable */
.reservations-table {
  .MuiTableCell-head {
    font-family: "CommutersSans" !important;
  }

  .MuiTableCell-root {
    border: 0 !important;
    padding: 24px 16px;
  }
}

.MuiTablePagination-select,
.MuiTablePagination-caption {
  font-family: "CommutersSans" !important;
}

.row-categories {
  align-items: flex-start !important;
  display: flex !important;
  flex-direction: column;
  text-decoration: none;
}

.vip {
  background-color: #000;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  line-height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.table-wrapper {
  border-collapse: collapse;
  overflow-x: auto;
  width: 100%;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  /* Estilos de las celdas */
}

@media (max-width: 600px) {
  .clients-table table th:nth-child(1),
  .clients-table table td:nth-child(1) {
    display: none;
  }
}

.scrollingdiv {
  position: relative;
}

.btnLeft {
  height: 100%;
  left: 0;
  position: absolute;
}

.buttonsContainer {
  display: flex;
  justify-content: end;
}

.btnRight {
  height: 100%;
  position: absolute;
  right: 0;
}

.table-date {
  text-transform: capitalize;
}

.nowrap {
  white-space: nowrap;
}

.guion {
  font-family: "Roboto";
  font-weight: 100;
}
.table-container {
  height: 800px;
  overflow: auto;
  width: 486px;
}
.clientname {
  color: var(--black);
  font-family: "Arial";
  font-size: 13.33px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.3px;
  line-height: normal;
  text-align: left;
  width: 100%;
}

.iconcake {
  height: 25px;
  width: 25px;
}

.cakespan {
  display: flex;
  vertical-align: center;
}

.cakeText {
  padding-top: 5px;
}

.fontAndaleMono {
  font-family: "Roboto";
  font-weight: 100;
}

.photocontainer {
  border: solid 1px #bfbfbf;
  border-radius: 50px 50px 1px 1px;
  padding: 4px;
  width: 100px;
}

.photocontainer>* {
  border-radius: 50px 50px 1px 1px;
}

.limiterRow {
  background-color: #f0f0f0 !important;
  color: #000 !important;
  font-family: CommutersSans !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  line-height: 1.334 !important;
  padding: 10px 15px !important;
}

.walkin {
  color: #000 !important;
  font-family: CommutersSans !important;
}

.capitalized {
  text-transform: capitalize !important;
}

table th {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1;

  // any bg-color to overlap
  // any positive value, layer order is global
}

.canceled {
  color: rgb(255, 0, 0);
}

.reservations-table .MuiTableCell-root {
  border: 0 !important;
  padding: 24px 5px !important;
}

.reducido {
  max-width: 100px !important;
  padding: 20px 5px;
  white-space: normal !important;
  width: 100px !important;
  word-wrap: break-word;
}

.reducido>* {
  white-space: normal !important;
  word-wrap: break-word;
}

.reducido2 {
  max-width: 50px !important;
  padding: 20px 5px;
  white-space: normal !important;
  width: 50px !important;
  word-wrap: break-word;
}

.reducido2>* {
  white-space: normal !important;
  word-wrap: break-word;
}

.iconpeople {
  height: 25px;
  width: 25px;
}
.flex-container {
  
  display: flex;
 
}

th {
  background: #242424;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  position: sticky;
 
}
