.restaurantCapacity-left-grid,
.restaurantCapacity-right-grid {
    position: relative;
}

.restaurantCapacity-right-grid::before {
    border: .7px solid rgba(0, 0, 0, .06);
    content: "";
    height: 100%;
    left: -1.5rem;
    position: absolute;
    top: 0;
    width: 0;
}

.restaurantCapacity-area-option {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.restaurantCapacity__cta-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2rem;
}

.restaurantCapacity__totalCapacity {
    border-top: .7px solid rgba(0, 0, 0, .06);
    font-family: "Roboto", sans-serif !important;
    font-size: 2rem;
    font-weight: 100;
    text-align: end;
    p>span {
        font-family: "CommutersSans", sans-serif !important;
        font-size: 2.7rem;
    }
}

.info-message-container {
      color: #184371;
      font-size: .85rem;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    background-color: #184371;
    font-size: .75rem;
}

.MuiSvgIcon-root.info-icon-tooltip {
  cursor: pointer;
  font-size: 1.2rem;
}

@media only screen and (max-width: 600px) {
    .restaurantCapacity-right-grid {
        padding-left: 1rem;
        padding-top: 2rem;
    }
    .restaurantCapacity__right-grid::before {
        border: 0;
        content: "";
        height: 0;
    }
    .restaurantCapacity-form-container {
        margin-right: 0 !important;
    }
}
