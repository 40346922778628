/* eslint-disable */
@import "/src/styles/vh_100";
@import "/src/styles/import_once";

@include import-once("layouts/ClientsEdit") {
    .clients-edit {
        @include vh-100;
        display: flex;
        margin: 2.5rem 0;
        position: relative;
    }

    .clients-edit__header {
        border-bottom: solid 0.5px rgba(0, 0, 0, .2);
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        padding-top: 2rem;
    }

    .clientEdit-left-grid,
    .clientEdit-right-grid {
        position: relative;
    }

    .clientEdit-right-grid::before {
        border: .7px solid rgba(0, 0, 0, .06);
        content: "";
        height: 100%;
        left: -1.5rem;
        position: absolute;
        top: 0;
        width: 0;
    }

    .mask-container {
        height: 150px;
        max-width: 200px;
        width: 200px;
    }

    .clientDataLeft__container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .clientDataRight__container {
        .back-button {
            align-items: center;
            display: flex;
            flex-direction: row;
            font-size: .75rem;
            justify-content: flex-start;
        }

        .professions {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .name {
            font-family: "CommutersSans";
            text-transform: uppercase;
        }

        .email {
            font-family: "Roboto";
            font-size: .75rem;
        }

        .phone {
            font-family: "Roboto";
            font-size: .75rem;
        }

        .MuiCheckbox-colorPrimary.Mui-disabled,
        .MuiFormControlLabel-label.Mui-disabled {
            color: rgba(0, 0, 0, 1);
            font-size: .75rem;
        }
    }

    .clientsEdit__categories-checkbox {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
    }

    .back-button__icon {
        font-size: .5rem;
        padding: 0 !important;

        .MuiSvgIcon-root {
            width: .5em;
        }
    }

    .fauxTab-button {
        font-size: .75rem !important;
    }

    .fauxTab-button.activeTab {
        border-bottom: 2.5px solid rgba(0, 0, 0, 1);
    }

    .badge-item {
        background-color: #fff;
        border: solid 1px #000;
        border-radius: 60px;
        font-family: "Roboto";
        font-size: .7rem;
        margin: 8px 10px;
        padding: 4px 8px;
        text-transform: capitalize;
        width: fit-content;
    }

    .clientsEdit-totals__container {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 2rem 0;
        width: 100%;
    }

    .clientsEdit-totals__item {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start
    }

    .clientsEdit-form__container {
        border-top: solid 0.5px rgba(0, 0, 0, .2);
        width: 100%;
    }

    .clientsEdit__tabs-separator {
        border-top: solid 0.5px rgba(0, 0, 0, .2);
        height: 1;
        width: 80%;
    }

    .clientsEdit-form__field-container {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1.7rem 0;
        width: 100%;

        .MuiInputBase-root.Mui-disabled {
            color: rgba(0, 0, 0, 1);
        }

        .Mui-disabled.MuiInput-underline::before {
            border-bottom: 0;
        }
    }

    .clientsEdit-form__buttonGroup {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
    }

    .accordionContainer {
        margin-top: 20px;
        width: 95%;
    }

    .accordionTitle {
        border-radius: 0;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.87);
        cursor: pointer;
        font-family: CommutersSans;
        font-size: .9rem;
        font-weight: 500;
        line-height: 1.75;
        min-width: 64px;
        
                text-transform: uppercase;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .MuiPaper-elevation1 {
        box-shadow: none !important;
    }

    @media only screen and (max-width: 600px) {

   
        .lista{
            display:flex;
            flex-direction:column;

        }
        .clientsEdit__categories-checkbox {
            display: flex !important;
            flex-direction: column !important;
            flex-wrap: wrap !important;
            padding: 10px 45px;
        }
        .encabezado {
            padding: 1px 39px !important;

        }

        .MuiAccordionSummary-root {
            padding: 8px 25px !important;
        }

        .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-disableElevation {
            margin-top: 5px !important;
        }

        .accordionContainer {
            margin-top: 20px;
            width: 100%;
        }

        .clients-edit {
            margin: 1rem 0 4rem;
        }

        .clients-edit__header {
            border-bottom: .3rem solid #000;
            padding-bottom: 1rem;
            padding-top: 1rem;
        }

        .clients-edit__right-grid::before {
            border: 0;
            content: "";
            height: 0;
        }

        .mask-container {
            height: 110px;
            max-width: 150px;
            width: 150px;
        }

        .clientDataRight__container {
            .email {
                word-break: break-all;
            }

            .rating {
                .MuiRating-root {
                    font-size: 1.5rem !important;
                }
            }
        }

        .clientsEdit-totals__container {
            flex-direction: column;
            justify-content: flex-start
        }

        .clientsEdit-totals__item {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }

        .clientsEdit-form__field-container {
            flex-direction: column;
            justify-content: flex-start;
        }

        .clientsEdit-form__container {
            margin-bottom: 2.5rem;
        }

        .clientEdit-right-grid::before {
            border: 0;
            content: "";
            height: 0;
        }

        .fauxTab-button {
            font-size: .67rem !important;
        }

        .clientsEdit__tabs-separator {
            width: 100%;
        }
    }
}

.MuiAccordionSummary-root {
    padding: 0 !important;
}

.dflex {
    align-items: center;
    display: flex;
    width: 100%;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
}

.spacebetween {
    justify-content: space-between;
}

.clientsEdit-totals__item {
    font-family: CommutersSans;
    text-transform: uppercase;
}

.clientsEdit-form__field-container {
    width: 95% !important;
}

.clientsEdit-form__field-container .MuiInputBase-root.Mui-disabled {
    width: 95% !important;
}

.noanim {

    animation-name: none !important;

}

.clientsEdit-form__field-container {
    margin: 25px 0;
}

.MuiAccordionDetails-root {
    padding: 0 !important;
}

.MuiTypography-body1 {
    font-family: Roboto;
    font-weight: bold;
}

.MuiGrid-container {
    align-items: flex-start !important;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.mr32 {
    margin-right: 32px !important;
}
.dflex{
    align-items: center;
    display: flex;
    justify-content: space-between;
}