.restaurantDescription__field-group {
    align-items: center;
    border-bottom: solid 0.5px rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    width: 100%;
}

.restaurantDescription__field-checkbox-group {
    align-items: center;
    border-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    .MuiTypography-body1 {
        font-size: .8rem;
    }
}

.restaurantDescription-form__field-container {
    line-break: anywhere;
    margin-right: 1rem;
    width: 100%;
    .color-grey {
        padding-bottom: 1rem;
    }
}

@media only screen and (max-width: 600px) {
    .restaurantDescription__field-group {
        justify-content: start;
    }
    .restaurantDescription-form__field-container {
        padding-right: .5rem;
        width: 65%;
    }
    .restaurantDescription-form__buttonGroup {
        width: 30%;
    }
}