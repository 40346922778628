@import "/src/index";
@import "/src/styles/vh_100";
@import "/src/styles/import_once";

@include import-once("layouts/Login") {
    .forgot-password {
        @include vh-100;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .logo {
        max-width: 160px;
    }
     .confidencial-tag {
        color: rgba(0,0,0,0.25);
        display: flex;
        flex-direction: row;
        position: fixed;
        top: 48%;
        .text {
            font-size: 12px;
            letter-spacing: .4rem;
            transform: rotate(270deg);
            white-space: nowrap;
        }
        .line {
            color: rgba(0,0,0,0.15);
            font-size: 14px;
            letter-spacing: 0;
        }
    }

    @media (min-width: 960px) and (max-width: 1050px) {
        .confidencial-tag {
            right: -300px;
        }
    }
       @media (min-width: 1051px) and (max-width: 1260px) {
        .confidencial-tag {
            right: -270px;
        }
    }
    @media (min-width: 1265px) and (max-width: 1450px) {
        .confidencial-tag {
            right: -300px;
        }
    }
    @media (min-width: 1450px)  {
        .confidencial-tag {
            right: -270px;
        }
    }
}

.logotitulo {
    font-family: "CommutersSans" !important;
    font-size: 50px;
    text-transform: uppercase;
}

.logosubtitulo {
    font-family: "RedWood" !important;
    font-size: 19px;
    letter-spacing: 5.0px;
    padding-left: 3px;
    text-transform: uppercase;
}

.MuiTypography-h5 {
    color: #6e6e6e;
    font-family: CommutersSans;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.334;
    margin-left: -15px !important;
    margin-top: 20px !important;
    text-align: left !important;
    text-transform: uppercase;
}
