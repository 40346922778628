@import "/src/styles/vh_100";
@import "/src/styles/import_once";
@include import-once("layouts/StatisticsGrap") {
.reservations-create {
     @include vh-100;
    display: flex;
    position: relative;
}
.btnBorrar {
    margin-top:20px;
}
.align-center {
    align-items: center !important;
}
.reservations-create__header {
    border-bottom: solid 0.5px rgba(0, 0, 0, .2);
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
}
.custom-button {
    border: 2px solid #000 !important;
}

.reservations-create__left-grid {
    position: relative;
}
.reservations-create__right-grid {
    position: relative;
}
.reservations-create__right-grid::before {
  border: .7px solid rgba(0, 0, 0, .06);
  content: "";
  height: 100%;
  left: -.75rem;
  position: absolute;
  top: 0;
  width: 0;
}

    @media only screen and (max-width: 600px) {
        .reservations-create__header {
            border-bottom: .3rem solid #000;
            padding-bottom: 1rem;
            padding-top: 1rem;
        }
        .reservations-create__right-grid::before {
            border: 0;
            content: "";
            height: 0;
        }

    }
}
.contenedora{
    min-height: 300px;

}
.pt-2{
    padding-top: 2rem;
}
.badgedate{
   background-color: #000;
   border-radius: 5px;
    color: #fff;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;

}
.diasbloqueadosholder{
    display: flex;
    flex-wrap: wrap;

}