.privacy__header,
.privacy__text-header {
    padding-top: 2rem;
}

@media only screen and (max-width: 600px) {
    .privacy__header {
        border-bottom: .3rem solid #000;
        padding-bottom: 1rem;
        padding-top: 1rem;
        text-align: center;
    }
}
