.notifications {
  &__header {
    margin-top: 1rem;
  }

  &__title {
    margin-left: 1rem !important;
  }

  &__searchBar {
    align-items: center; // Ahora está bien posicionado
    background-color: #fff; // Color corto
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Asegúrate de que no haya unidad en 0
    padding: 0.5rem;

  }

  &__tabs-container {
    margin-top: 1rem;
    padding: 0 1rem;
  }
}

.notifications-table__box {
  margin-top: 1rem;
}
