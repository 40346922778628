.reservationsCreateForm {
    .MuiFormGroup-root {
        flex-direction: row;
    }
    .MuiFormControl-root {
        width: 100%;
    }
}

.reservationsCreateForm__areas {
    border-bottom: solid 1px rgba(0, 0, 0, 0.25);
    margin: .3rem 0;
    padding-bottom: .3rem;
}

.reservationsCreateForm__select-column-container {
    display: flex;
    flex-direction: column !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    overflow-x: hidden;
    scrollbar-width: none;
    /* Firefox */
    width: 100%;
}

.reservationsCreateForm__select-column-container::-webkit-scrollbar {
    display: none;
}

.reservationsCreateForm__area-column {
    width: 250px;
}

.reservationsCreateForm__checkbox-container {
    border-right: solid 1px rgba(0, 0, 0, 0.25);
    padding-left: .76rem;
    white-space: nowrap;
}

.reservations-accordion__expansion {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
}

.reservationsCreateForm__accordion-container {
    border-right: solid 1px rgba(0, 0, 0, 0.25);
    padding-left: .2rem;
}

.reservations-accordion__expansion .reservationsCreateForm__accordion-container:nth-child(3n) {
    border-right: 0;
}

.reservationsCreateForm__button-group {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .reservationsCreateForm {
        .MuiFormGroup-root {
            flex-direction: column;
        }
    }
}