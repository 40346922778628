@import "/src/styles/vh_100";
@import "/src/styles/import_once";
@include import-once("layouts/SplashScreen") {
    .global-container {
        max-width: 100% !important;
        padding: 0 !important;
        width: 100% !important;
    }
    .splash-screen {
        @include vh-100;
        align-items: center;
        display: flex !important;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
    .splash__right-half,
    .splash__left-half {
        @include vh-100;
        animation-duration: 1.5s;
        background-color: #fff;
        width: 50%;
    }
    .splash__right-half {
        animation-name: slideoutright;
    }
    .splash__left-half {
        animation-name: slideoutleft;
    }
    @keyframes slideoutright {
        0% {
            transform: translateX(0);
        }
        20% {
            background-color: #fff;
        }
        25% {
            background-color: #000;
        }
        70% {
            background-color: #000;
            transform: translateX(0);
        }
        100% {
            background-color: #000;
            transform: translateX(100%);
        }
    }
    @keyframes slideoutleft {
        0% {
            transform: translateX(0);
        }
        20% {
            background-color: #fff;
        }
        25% {
            background-color: #000;
        }
        70% {
            background-color: #000;
            transform: translateX(0);
        }
        100% {
            background-color: #000;
            transform: translateX(-100%);
        }
    }
    .splash__logo-group {
        position: absolute;
        z-index: 2000;
    }
    .splash__logo-container {
        @include vh-100;
        position: relative;
        .icon-container {
            // background-color: #fff;
            height: 86.1px;
            padding: 20px 0;
            position: absolute;
            top: 40%;
            width: 63.1px;
        }
        .icon {
            height: 86.1px;
            object-fit: contain;
            transform: translateX(-44%);
            width: 63.1px;
        }
        .line-container {
            height: inherit;
            position: absolute;
            top: 0;
        }
        .line {
            background-color: #000;
            border: 1px solid rgba(0, 0, 0, 1);
            height: 40vh;
            width: 0;
        }
        .line.bottom {
            bottom: 0;
            position: absolute;
        }
    }
}