.restaurant__tabs-box {
    border-bottom: solid 0.5px rgba(0, 0, 0, .2);
    padding: 1.2rem 0 0;
}

@media only screen and (max-width: 600px) {
    .reservations__tabs-box {
        padding: 0;
    }
}
