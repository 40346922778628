.staff-table {
    .MuiTableCell-head {
        font-family: "CommutersSans" !important;
        text-transform: uppercase;
    }
    .MuiTableCell-root {
        border: 0 !important;
        padding: 24px 16px;
    }
}

.MuiTablePagination-select,
.MuiTablePagination-caption {
    font-family: "CommutersSans" !important;
}

.mask-container {
    height: 150px;
    max-width: 200px;
    width: 200px;
}

.row-name .content {
    align-items: center;
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
}
.blackbadge {
    background-color: #000;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-family: "CommutersSans";
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    margin: 5px 7px;
    padding: 4px 8px;
    text-transform: uppercase;

}
