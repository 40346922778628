@import "/src/styles/vh_100";
@import "/src/styles/import_once";
@include import-once("layouts/RecoverPassword") {
    .recover-password {
        @include vh-100;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .logo {
        max-width: 160px;
    }
}

.logotitulo {
    font-family: "CommutersSans" !important;
    font-size: 50px;
    text-transform: uppercase;
}

.logosubtitulo {
    font-family: "RedWood" !important;
    font-size: 19px;
    letter-spacing: 5.0px;
    padding-left: 3px;
    text-transform: uppercase;
}