* {
    -webkit-font-smoothing: antialiased;
}

.the-menu {
    border-bottom: solid 0.5px rgba($color: #000, $alpha: 0.1);
    display: flex;
    .the-menu__toolbar {
        display: flex;
        justify-content: space-between;
        padding: 0 !important;
    }
}

.the-menu.white__separator {
    border-bottom: solid 0.25px rgba($color: #fff, $alpha: 1);
}

.the-menu__container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.the-menu__separator {
    border: solid 0.3px #000001;
    height: 32px;
    margin: 0 1.1rem;
    opacity: 0.1;
    width: 0;
}

.the-menu__separator.white__separator {
    border: solid 0.3px #fff;
    height: 32px;
    margin: 0 1.1rem;
    opacity: 1;
    width: 0;
}

.the-menu-logo {
    max-width: 120px;
}

.the-menu-logotwo {
    color: #fff;
    max-width: 120px;
}

.the-menu__time-box {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.the-menu-time {
    text-transform: capitalize;
}

.the-menu__time-box-mobile {
    align-items: center;
    border: solid 0.3px rgba($color: #000, $alpha: 0.1);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.the-menu__burger-button .MuiSvgIcon-root {
    font-size: 2rem !important;
}

.the-menu__buttons {
    background-color: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 1.35rem;
    margin: 0;
    text-transform: uppercase;
}

.the-menu__buttons-legals {
    background-color: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    margin: 0;
}

.the-menu__open-menu-container {
    height: 100vh;
    overflow: hidden;
}

.the-menu__mobile {
    align-items: center;
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: space-between;
    min-height: 56px;
    position: relative;
    .the-menu__mobile-button {
        left: 0;
        margin-left: .3rem;
        position: absolute;
    }
    .the-menu__mobile-text {
        cursor: pointer;
    }
}
.dot {
    background-color:#ff3616;
    border-radius: 50%;
    height: 10px;
    margin-right: 5px;
    width: 10px;
}

@media only screen and (max-width: 600px) {
    .the-menu {
        bottom: -2px;
        position: fixed !important;
    }
    .the-menu {
        .the-menu__toolbar {
            justify-content: center;
        }
    }
    .the-menu__open-menu-container {
        height: 100vh;
    }
}

.the-menu__open-menu-container {
    height: 100vh;
    padding-top: 10vh;
}
.mt-5 {
    margin-top: 10vh !important;
}