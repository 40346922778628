  .dialog-edit-restaurant-description-modal {
      background: #fff;
      box-shadow: 0 0.4375rem 4rem 0 rgba(0, 0, 0, 0.07);
      left: 50%;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 28rem;
  }
  
  .dialog-edit-restaurant-description-modal__overlay {
      background-blend-mode: multiply;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      height: 100vh;
      position: fixed;
      width: 100vw;
      z-index: 30000;
  }
  
  .dialog-edit-restaurant-description-modal__content {
      padding: 1.5rem;
  }
  
  .dialog-edit-restaurant-description-modal__title {
      text-transform: uppercase;
  }
  
  .dialog-edit-restaurant-description-modal__cta-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 2rem;
  }
  
  .dialog-edit-restaurant-kitchens-checkboxes__container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-height: 14.5rem;
      overflow-y: scroll;
  }
  
  .dialog-edit-restaurant-kitchens-checkbox-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
  }
  
  @media only screen and (max-width: 600px) {
      .dialog-edit-restaurant-description-modal {
          max-width: 90%;
      }
  }