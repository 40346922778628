 .transition-group {
     flex: 1;
     position: relative;
 }

 .router {
     bottom: 0;
     left: 0;
     position: absolute;
     right: 0;
     top: 0;
 }

 .layout {
     bottom: 0;
     left: 0;
     position: absolute;
     right: 0;
     top: 0;
 }

 .fade-enter {
     .layout {
         opacity: 0;
         z-index: 1;
     }
     &.fade-enter-active {
         /* stylelint-disable-next-line max-nesting-depth */
         .layout {
             opacity: 1;
             transition: opacity 150ms ease-in-out;
         }
     }
 }
