.table-Pagination__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.pageSize__buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pageSize__select {
    font-family: "CommutersSans" !important;
}