@import "/src/styles/vh_100";
@import "/src/styles/import_once";

@include import-once("layouts/Login") {
    .login {
        @include vh-100;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .logo {
        max-width: 160px;
    }

    .confidencial-tag {
        color: rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        position: fixed;
        top: 48%;

        .text {
            font-size: 12px;
            letter-spacing: .4rem;
            transform: rotate(270deg);
            white-space: nowrap;
        }

        .line {
            color: rgba(0, 0, 0, 0.15);
            font-size: 14px;
            letter-spacing: 0;
        }
    }

    .login__buttons-legals {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        margin: 0;
    }

    @media (min-width: 960px) and (max-width: 1050px) {
        .confidencial-tag {
            right: -300px;
        }
    }

    @media (min-width: 1051px) and (max-width: 1260px) {
        .confidencial-tag {
            right: -270px;
        }
    }

    @media (min-width: 1265px) and (max-width: 1450px) {
        .confidencial-tag {
            right: -300px;
        }
    }

    @media (min-width: 1450px) {
        .confidencial-tag {
            right: -270px;
        }
    }
}

.logotitulo {
    font-family: "CommutersSans" !important;
    font-size: 50px;
    text-transform: uppercase;
}

.logosubtitulo {
    font-family: "RedWood" !important;
    font-size: 19px;
    letter-spacing: 5.0px;
    padding-left: 3px;
    text-transform: uppercase;
}