.reservations {
  display: flex;
  flex-direction: column;
  position: relative;
}

.MuiDrawer-paper {
  z-index: 999999 !important;
}

.reservations__header {
  padding-top: 2rem;
}

.reservations__tabs-container {
  max-width: 100%;
  width: 100%;
}

.MuiBadge-anchorOriginTopRightRectangle {
  z-index: 9999 !important;
}

.reservations-table__box {
  max-width: 100%;
  overflow-x: scroll;
}

.reservations__searchBar {
  background-color: #fff;
  padding: .2rem 1.2rem;
}

.reservations__title {
  text-align: center;
}

.reservations__title-date {
  text-transform: capitalize;
}

.reservations__title-separator {
  color: rgba(0, 0, 0, 0.2);
  margin: 0 0 0 .5rem;
}

.reservations-alerts__box {
  padding: 1rem 0;
}

.reservations-alerts__badge {
  background-color: #f0f0f0;
  padding: .5rem .75rem;
}

.reservations-alerts__date {
  padding-top: .7rem;
}

.reservations__title-date {
  color: #000 !important;
}

.MuiTypography-body1 {
  color: #000;
}

@media only screen and (max-width: 600px) {

  .sm-py2 {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .flexible {
    border-top: solid 1px #ddd;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .MuiBox-root.MuiBox-root-22.reservations__header.dFlex.flex-row.align-center.flexfix {
    padding-bottom: 0;
  }

  .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    padding: 10px 0;
  }

  .flexfix {
    flex-wrap: wrap;
  }

  .reservations__header {
    border-bottom: .3rem solid #000;
    padding-bottom: 0 !important;
    padding-top: 1rem;
  }

  .reservations__tabs-container {
    margin-top: 0 !important;
    padding: 1rem 0 !important;
  }

  .reservations__searchBar {
    padding: 1rem .2rem;
    transform: translate3d(0, 13px, 0) !important;
    width: 100%;
  }

  .reservations__searchBar .MuiFormControl-root {
    width: 85%;
  }

  .reservations__title.MuiTypography-h5 {
    font-size: 1.2rem;
  }

  .reservations-alerts__date {
    padding-top: 0;
  }
}

.toggle-switch {
  display: inline-block;
  height: 25px;
  position: relative;
  width: 50px;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .switch {
  background-color: #ccc;
  border-radius: 25px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 0.2s ease;
}

.toggle-switch .switch::before {
  background-color: #aaa;
  border-radius: 50%;
  content: "";
  height: 21px;
  left: 2px;
  position: absolute;
  top: 2px;
  transition: transform 0.3s ease;
  width: 21px;
}

.toggle-switch input[type="checkbox"]:checked+.switch::before {
  background-color: #69c;
  transform: translateX(25px);
}

.toggle-switch input[type="checkbox"]:checked+.switch {
  background-color: #369;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: rgb(0, 0, 0);
}

.MuiSwitch-switchBase {
  color: rgb(0, 0, 0) !important;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.ant-switch {
  margin-left: 5px !important;
}

.ant-switch-checked {
  background: #000;
}

.MuiTypography-body1 {
  font-family: "CommutersSans", sans-serif;
}

.MuiButton-label {
  letter-spacing: 1.6px;
}

.sborder {
  border: 1px solid #ddd;
  text-align: center;
}

.sborderhorizontal {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  text-align: center;
}

.sbordertop {
  border-top: 1px solid #ddd;
}

.error-icon {
  margin-bottom: -25px;
  margin-top: 60px;
  max-width: 120px;
}

.MuiIconButton-label {
  align-items: inherit;
  display: flex;
  font-family: "CommutersSans" !important;
  justify-content: inherit;
  text-transform: capitalize !important;
  width: 100%;
}

.reservations__header {
  border: solid 1px #bfbfbf !important;
  border-top: 0 !important;
  padding-top: 0 !important;
}

.MuiTypography-root.reservations__title.font__commutter.MuiTypography-h5 {
  margin-top: 0 !important;
}

.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.reservations__title-date {
  display: block;
  text-align: center;
  width: 100%;
}

.MuiFormControlLabel-labelPlacementStart {
  justify-content: center !important;
}

.btnnegro {
  background: #000;
  font-size: 19px !important;
  min-height: 72px;
  width: 100%;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.active {
  border-bottom: solid 2px #000;
}

.colornegro {
  background: #000 !important;
}

.conmuterbold {
  font-family: "CommutersSans", sans-serif;
  font-weight: bold;
}

.flexible {
  align-items: center;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: flex-end;
  padding-top: 10px;
}
.flex-end {
  justify-content: flex-end !important;
}
.switcher {
  flex-wrap: nowrap;
}

.btnblanco {
  background: #fff !important;

  border: solid #000 2px !important;
  border-radius: 50px;
  color: #000 !important;
  margin-top: 5px !important;
  padding: 0 !important;
}

.textblack {
  border-radius: 50px;
  padding: 6px 10px;
}

.textblack {
  color: #000 !important;
}

.withoutLBorder {
  border-left: solid 0 #fff !important;
}

.bottomButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 15px;
}

.btnblanco :hover {
  border-radius: 50px;
}

.circleRadius {
  border-radius: 50px !important;
}

.btnblanco :hover {
  background: #000 !important;
  color: #fff !important;
}

.MuiBadge-colorPrimary {
  background-color: rgb(255, 53, 53) !important;
  color: #fff;
}
.MuiBadge-colorSecund {
  background-color: rgb(183, 56, 194) !important;
  color: #fff;
}
.MuiBadge-colorBLock {
  background-color: rgb(0, 0, 0) !important;
  color: #fff;
}

.MuiButton-disableElevation :hover>* {
  background: #000 !important;
  color: #fff !important;
}

.MuiButton-label {
  font-size: 12px;
  white-space: nowrap;
}

.circleRadius:hover {
  background: #000 !important;
  color: #fff !important;
}
.justify-content-between {
  justify-content: space-between !important;
}

.MuiButton-containedPrimary:hover {
  background: #000 !important;
  color: #fff !important;
}

@media only screen and (max-width: 600px) {
  .bottomButtons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 15px;

  }

  .circleRadius {
    margin-left: 1% !important;
    width: auto !important;
  }

  .bottomButtons {
    display: block !important;
    font-size: 12px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    padding-top: 10px;
    white-space: nowrap;
  }

  .btnblanco {
    font-size: 12px !important;
  }

}

.arrowButtons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 15px;
}

.arrowbuttom {
  background: #000 !important;
  border-radius: 40px;
  color: #fff !important;
  height: 40px;
  margin-top: 5px !important;
  padding: 0 !important;
  width: 40px;
}

.bottomButtons::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bottomButtons {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.containerFluid {
  max-width: 95% !important;
}

.text-blue {
  color: #7448FF !important;
}

.text-pink {
  color: #fc61AA !important;
}

.text-green {
  color: #50bf50 !important;
}
.reservationsvisible {
  font-size: 12px !important;
}
.mb-10{
  margin-bottom: 10px !important;
}
.pr-2{
  padding-right: 5px !important;
}
.MuiBadge-colorSecondary {
  background-color: #0b5394 !important;
  color: #fff !important;

}
.MuiBadge-colorBlack {
  background-color: rgb(0, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
}
.MuiBadge-colorTertiary {
  background-color: #000408 !important;
  color:  #000 !important;
}

.Tertiary {
  background-color: #000408 !important;
  color:  #000 !important;
}
.MuiBadge-colorFourthdary {
  background-color: #9800c2 !important;
  color: #fff !important;

}
