.dialog-email-modal {
  background: #fff;
  box-shadow: 0 0.4375rem 4rem 0 rgba(0, 0, 0, 0.07);
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 28rem;
}

.dialog-email-modal__overlay {
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5));
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 30000;
}

.contenido {
    color: #f00;
    font-family: "CommutersSans";
    font-size: 16px;
    margin-top: 20px;
  }

.dialog-email-modal__content {
  padding: 1.5rem;
}

.dialog-email-modal__title {
  text-transform: uppercase;
}

.line {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10 0;
  margin-top: 20px;

}

.labeltitle {
  color: #000;
  font-family: "CommutersSans";


  font-size: 16px;
  font-weight: bold;
}

.numbersquare {
  border: solid 1px #000;

  font-size: 20px;
  font-weight: bold;
  padding: 20px 15px;
}

.dialog-email-modal__cta-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2rem;
}

@media only screen and (max-width: 600px) {
  .dialog-email-modal {
    max-width: 90%;
  }
}

.titulo {
  color: var(--black);
  font-family: CommutersSans;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
}

.correo {
  color: var(--black);
  display: block;
  font-family: Roboto;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: normal;
  margin-top: 24px;
  text-align: left;
}

.telefono {
  color: var(--black);
  font-family: Roboto;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: normal;
  margin: 5px 69px 109px 0;
  text-align: left;
}

.blackbtn {
  background-color: var(--black);
  height: 40px;
  margin: 109px 0 0 27px;
  padding: 10px 32px;
  width: 148px;
}

.flexend {
  display: flex;
  justify-content: end;
}

.flexcenter {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.labeltitle {
  font-family: "CommutersSans";
  font-weight: bold;
}