.border{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.3rem !important;
  padding: 0.4rem 0.7rem !important;
}

.border2{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.3rem !important;
  padding: 0.4rem 0.2rem !important;
}

.border-menu-accordion{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.3rem !important;
}

.border-menu-drag--section:hover{
  background-color: #d6cece85;
  filter: brightness(0.9);
}

.border-menu-drag--section-selected{
  background: #fff !important;
  filter: invert(100%);
  .photocontainer{
    filter: invert(100%);
  }
}

.texto-verde{
  color: rgb(62, 197, 62);
  font-size: 0.8rem !important;

  &:hover{
    cursor: pointer;
  }
}

.border-menu-drag--section-selected:hover{
  background: #fff;
  filter: invert(100%);
  .photocontainer{
    filter: invert(100%);
  }
}


.border-menu-drag{
  background-color: #fff;
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.3rem !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative !important;
  z-index: 3;

  &--header{
    align-items: center;
    border-bottom: .7px solid hsla(0, 2%, 60%, 0.522) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &--section{
    align-items: center;
    border-bottom: .7px solid hsla(0, 2%, 60%, 0.522) !important;
    border-top: .7px solid hsla(0, 2%, 60%, 0.522) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &--footer{
    border-top: .7px solid rgba(156, 152, 152, 0.521) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.texto-opcion{
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
  &:hover{
    background-color: #505050;
    cursor: pointer;
  }
}

.border-menu-accordion-item{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
}

.bold-font{
  font-weight: bold !important;
}

.border-bottom{
  border-bottom: .7px solid rgba(156, 152, 152, 0.521) !important;
}

.pr-2e{
  padding-right: 1rem !important;
}

.pr-1e{
  padding-right: 0.5rem !important;
}

.mb-2e{
  margin-bottom: 2rem;
}

.mb-4e{
  margin-bottom: 4rem;
}

.arrows-scroll{
  border: .7px solid rgba(156, 152, 152, 0.521);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  height: 25rem;
  justify-content: space-between;
  left: -26px;
  position: absolute;
}

.border-top{
  border-top: .7px solid rgba(156, 152, 152, 0.521) !important;
}

.self-center{
  align-self: start;
}

.MuiInput-underline::before{
  border-bottom: 0 !important;
}

.botonGuardar{
  padding: calc(0.4rem + 6px) 2rem !important;;
}

.mt-05{
  margin-top: 0.5rem !important;
}

.w94{
  width: 94%;
}

.w95{
  width: 95%;
}

.w99{
  width: 99%;
}

.w50{
  width: 50%;
}

.w80{
  width: 80%;
}

.w80i{
  width: 80% !important;
}

.w3-8r{
  width: 3.8rem;
}

.w5-9r{
  width: 5.9rem;
}

.w7-9r{
  width: 7.6rem;
}

.circulo-rojo{
  background: #f00;
  border-radius: 50%;
  height: 20px;
  left: 90px;
  position: absolute;
  top: -10px;
  width: 20px;
}

.circulo-rojo2{
  background: #f00;
  border-radius: 50%;
  height: 20px;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
}

.w3r{
  width: 3rem;
}

.timePicker{
  display: none;
}

.no-padding-select div{
  font-size: 0.9rem !important;
  padding-right: 0 !important;
}

.font-size-commutter{
  @media screen and (max-width: 1024px) and (min-width: 768px){
    font-size: 0.7rem !important;
  }
  @media screen and (min-width: 1025px){
    font-size: 0.89rem !important;
  }
}

.button-x{
  position: absolute; 
  right: 5px; 
  top: 5px;
}

.input-tiny{
  height: 2.1rem;
  padding: 0.1rem 0.2rem !important;
  width: 2.1rem;
}

.input-tiny2{
  height: 2.1rem;
  padding: 0.1rem 0.2rem !important;
  width: 2.5rem;
}

.pt-1e{
  padding-top: 4px;
}

.icon-mesa{
  height: 5rem;
}

.photocontainer2 {
  width: 70px !important;
}

.photocontainer3 {
  height: 90px;
  max-width: 100px;
  width: 100%;
}

.tiny-font-tittle{
  @media screen and (max-width: 1024px) and (min-width: 768px){
    font-size: 0.7rem !important;
  }
  @media screen and (min-width: 1025px){
    font-size: 0.89rem !important;
  }
}

.floormap-icon{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.2rem !important;
  padding: 0.5rem !important;
  width: 2.9rem;
}

.floormap-icon:disabled{
  opacity: .4;
}

.icon-mesa2{
  position: relative;
  text-align: center;

  &--texto{
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.modal__box{
  align-items: center;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 1rem;
  box-shadow: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  &__input{
    margin: 2rem 0 !important;
  }

  &__menu{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    margin-bottom: 2rem;
    width: 20rem;
  }
}

.modal__box2{
  align-items: center;
  background-color: #fff;
  box-shadow: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;

  @media screen and (max-width: 768px) {
    height: 90vh;
    justify-content: normal;
    overflow-y: scroll;
    width: 96vw;
  }

  &__input{
    margin: 2rem 0 !important;
  }

  &__menu{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    margin-bottom: 2rem;
    width: 20rem;
  }
}

.modal__box3{
  align-items: center;
  background-color: #fff;
  box-shadow: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;

  @media screen and (max-width: 768px) {
    justify-content: normal;
    overflow-y: scroll;
    width: 96vw;
  }

  &__input{
    margin: 2rem 0 !important;
  }

  &__menu{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    margin-bottom: 2rem;
    width: 20rem;
  }
}

.inside-input{
  padding: 0 !important;
}

.search-input{
  font-size: 0.7rem !important;
}

.search-box{
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 2.9rem;
  justify-content: center;
}

.filter-box{
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ple-1{
  padding-left: 0.7rem;
}

.floormap-icon3{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.2rem !important;
  height: 2.9rem;
  padding: 0.5rem !important;
  width: 2.9rem;
}

.floormap-icon4{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.2rem !important;
  height: 2.9rem;
  padding: 0.5rem !important;
}

.floormap-drag-button{
  height: 2.9rem;
  padding: 0.5rem !important;
  width: 2.9rem;
}

.floormap-scroll-button{
  height: 2.9rem;
  padding: 0.5rem !important;
  width: 1.5rem;
}

.tiny-font3{
  color: #505050 !important;
  font-size: 0.8rem !important;
}

.tiny-font4{
  color: #505050 !important;
  font-size: 0.7rem !important;
}

.tiny-font6{
  color: #505050 !important;
  font-size: 1rem !important;
}

.tiny-font2{
  color: #505050 !important;
  font-size: 0.7rem !important;
  text-decoration: underline;
}

.margin-image{
  padding-right: 5%;
}

.margin-image2{
  padding-right: 2.9rem;
}

.prueba{
  display: flex;
  flex-direction: row;
}

.contacto-section{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 8rem;
}

.categoria-section{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 8rem;
}

.zona-section{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 4rem;
}

.personas-section{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 4rem;
}

.status-section{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 8rem;
}

.comentarios-section{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 8rem;
}

.visitas-section{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 4rem;
}