.reservation-tabs__numbers {
    font-size: 1.5rem;
}

.reservations__tabs-box {
    border-bottom: solid 0.5px rgba(0, 0, 0, .2);
    padding: 0 0 1.2rem;
}

.restaurant-schedule__time-picker-box {
    cursor: pointer;
    margin-left: 8px !important;
}

.restaurant-schedule__time-picker-box input {
    cursor: pointer;
    max-width: 70px;
}

.restaurant-schedule__time-picker-box .MuiInputBase-root .MuiInputAdornment-positionEnd {
    margin-left: 0;
}
.order-1{
    order:1 !important;
}
.order-2{
    order:2 !important;
}
.order-3{
    order:3 !important;
}
.order-4{
    order: 4 !important;
}
.order-5{
    order:5 !important;
}
 .order-6{
    order:6 !important;
}
.order-7{
    order:7 !important;
}
.order-8{
    order:8 !important;
}
.order-9{
    order:9 !important;
}
.order-10{
    order:10 !important;
}
.order-11{
    order:10 !important;
}
.order-12{
    order:10 !important;
}
@media only screen and (max-width: 600px) {
    .reservations__tabs-box {
        padding: 0;
    }
    
    .order-1{
        order:1 !important;
    }
    .order-2{
        order:2 !important;
    }
    .order-3{
        order:3 !important;
    }
    .order-4{
        order:4 !important;
    }
    .order-5{
        order:5 !important;
    }
     .order-6{
        order:6 !important;
    }
}