.comments {
    display: flex;
    flex-direction: column;
    position: relative;
}

.comments__header {
    align-items: center;
    border-bottom: .05rem solid rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.comments__tabs-container {
    max-width: 100%;
    width: 100%;
}

.comments-table__box {
    max-width: 100%;
    overflow-x: scroll;
}

.comments__searchBar {
    background-color: #fff;
    padding: .2rem .5rem;
}

.comments-table__box {
    overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
    .comments__header {
        align-items: center;
        border-bottom: .3rem solid #000;
        justify-content: center;
        padding-bottom: 1rem;
        padding-top: 1rem;
        position: relative;
        button {
            left: 1rem;
            position: absolute;
            top: 1rem;
        }
    }
    .comments__tabs-container {
        margin-top: 0 !important;
        overflow-x: hidden;
        padding: 1rem 0 !important;
    }
    .comments__searchBar {
        max-width: 74%;
        padding: 1rem .2rem;
        transform: translateX(26%) !important;
        width: 100%;
    }
    .comments__searchBar .MuiFormControl-root {
        width: 85%;
    }
}