  .dialog-clientImage-modal {
      left: 50%;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 28rem;
  }
  
  .dialog-clientImage-modal__overlay {
      background-blend-mode: multiply;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      height: 100vh;
      position: fixed;
      width: 100vw;
      z-index: 30000;
  }
  
  .dialog-clientImage-modal__content {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 1.5rem;
  }
  
  @media only screen and (max-width: 600px) {
      .dialog-clientImage-modal {
          max-width: 90%;
      }
  }