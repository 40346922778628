.restaurantSchedule-left-grid,
.restaurantSchedule-right-grid {
    position: relative;
}

.restaurantSchedule-right-grid::before {
    border: .7px solid rgba(0, 0, 0, .06);
    content: "";
    height: 100%;
    left: -1.5rem;
    position: absolute;
    top: 0;
    width: 0;
}

.restaurantSchedule-area-option {
    align-items: center;
    border-bottom: 1.5px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.restaurantSchedule__cta-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2rem;
}

.restaurant-schedule__time-picker .MuiInput-underline::before,
.restaurant-schedule__time-picker .MuiInput-underline::after {
    border: 0 !important;
}

.info-message-container {
      color: #184371;
      font-size: .85rem;
}

.column33 {
    width: 33.3%;
}
.column66 {
    width: 66.6%;
}
.w50 {
    width: 50%;
}
@media only screen and (max-width: 600px) {
    .MuiButton-disableElevation {
        margin-top: 0 !important;
    }
    .MuiButton-containedSecondary {
        margin-top: 5px !important;
    }
    .restaurantSchedule-right-grid {
        padding-left: 1rem;
        padding-top: 2rem;
    }
    .restaurantSchedule__right-grid::before {
        border: 0;
        content: "";
        height: 0;
    }
    .restaurantSchedule-area-option {
    align-items: flex-start;
    }
    .dashedBorderLeft{
        border-left: unset !important;
        padding-left:0 !important;
    }

}

.dflex{
    display: flex;
}
.textCenter{
    justify-content: center;
    padding-bottom:10px;
    text-align: center;

}
.dashedBorderLeft{
    border-left: 2px dashed #000;
    padding-left:10px;
}
.MuiButton-disableElevation {
    margin-top: 0 !important;
}
.margintopfix{
    margin-top:5px !important;
}
