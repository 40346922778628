.menu-changePassword-options__menu-item {
    font-family: "CommutersSans" !important;
    font-size: .7rem;
}

.menu-changePassword-options {
    color: #000;
}

.menu-changePassword__button {
    transform: translateX(12px);
}

.menu-changePassword__button .MuiSvgIcon-root {
    font-size: 2rem !important;
}

@media only screen and (max-width: 600px) {
    .menu-changePassword__button {
        transform: translateX(0);
    }
    .restaurantName{
       display  : none !important; 
        
    }
}
.the-menu__mobile .the-menu__mobile-text {
    color: #fff;
}

.dflex{
    display:flex;
}
.restaurantName{
    font-weight:bold;
    
}
.topRestaurantName{
    white-space: break-spaces !important;
}
.MuiDivider-root {
      background-color: #000 !important;
      border: unset !important;
      flex-shrink: 0;
      height: 2px !important;
      margin: 0;
  }