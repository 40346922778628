.border{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.3rem !important;
  padding: 0.4rem 0.7rem !important;
}

.border-menu-accordion{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.3rem !important;
}

.border-menu-accordion-item{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
}

.bold-font{
  font-weight: bold !important;
}

.font-text{
  font-family: "Roboto";
}

.leyenda-floormap {

  border-radius: 50%;
  display: block;
  height: 18px;
  margin-right: 5px;
  min-width: 18px;
  width: 18px;



}
.leyenda-floormap2 {

  border-radius: 50%;
  display: block;
  height: 18px;
  margin-right: -7.5px;
  min-width: 18px;
  width: 18px;
  z-index: 99;



}
.leyendarow-floormap{


  display: flex;

  margin-bottom: 5px;
}

.rojo {
  background: #cb141d;
}
.rojoClaro {
  background: #ff1925;
}
.verde {
  background: #00a650;
}
.amarillo  {
  background: #f7cf1d;
}
.rosado  {
  background: #f71dc8;
}
.naranja  {
  background: #f7941d;
}
.blanco  {
  background: #fffefd;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
}
.azuloscuro {
  background: #0b5394;
}
.oscuro {
  background: #000408;
 
  right: 14px !important;
  
}
.morado {
  background: #9800c2;
}

.border-bottom{
  border-bottom: .7px solid rgba(156, 152, 152, 0.521) !important;
}

.pr-2e{
  padding-right: 1rem !important;
}

.pt-3e{
  padding-top: 0 !important;
}

.border-top{
  border-top: .7px solid rgba(156, 152, 152, 0.521) !important;
}

.MuiInput-underline::before{
  border-bottom: 0 !important;
}

.botonGuardar{
  padding: calc(0.4rem + 6px) 2rem !important;;
}

.mt-05{
  margin-top: 0.5rem !important;
}

.w94{
  width: 94%;
}

.w50{
  width: 50%;
}

.floormap-icon{
  border: .7px solid rgba(156, 152, 152, 0.521) !important;
  border-radius: 0.2rem !important;
  padding: 0.5rem !important;
  width: 2.9rem;
}

.floormap-icon:disabled{
  opacity: .4;
}

.modal__box{
  align-items: center;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 1rem;
  box-shadow: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  &__input{
    margin: 2rem 0 !important;
  }

  &__menu{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    margin-bottom: 2rem;
    width: 20rem;
  }
}

.inside-input{
  padding: 0 !important;
}

.ple-1{
  padding-left: 0.7rem;
}

.search-client-option:hover{
  background-color: #d6cece85;
  filter: brightness(0.9);
}

.w10{
  width: 10% !important;
}

.w5{
  width: 5% !important;
}

.w20{
  width: 20% !important;
}

.w25{
  width: 25% !important;
}

.w30{
  width: 30% !important;
}

.w65{
  width: 65% !important;
}

.w69{
  width: 68.5% !important;
}

.w70{
  width: 70% !important;
}

.w15{
  width: 15% !important;
}

.w8{
  width: 8% !important;
}

.text-overflow{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.tiny-font5{
  font-size: 9px !important;
}

.scroll{
  overflow-x: scroll;
  width: 1360px;
}