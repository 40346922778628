@font-face {
    font-family: "CommutersSans";
    src: url("fonts/Dharma-Type-Commuters-Sans-SemiBold.woff") format("woff"),
}
@font-face {
    font-family: "RedWood";
    src: url("fonts/RWR.woff") format("woff"),
}
* {
    -webkit-font-smoothing: antialiased;
}

body {
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// Cualquier elemento width = 100%
.w100 {
    width: 100%;
}

.w75 {
    width: 75%;
}

.w50 {
    width: 50%;
}

.w25 {
    width: 25%;
}

// Imágenes 100% de su contenedor
.img-fluid {
    height: auto;
    max-width: 100%;
}

// Las siguientes clases son Alineaciones Flex inspiradas en Bootstrap
.dFlex {
    display: flex;
}

.dNone {
    display: none !important;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-start {
    align-items: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.justify-evenly {
    justify-content: space-evenly;
}

.justify-around{
    justify-content: space-around;
}

// Paddings y Margins
.p1 {
    padding: 1rem;
}

.p2 {
    padding: 2rem;
}

.p3 {
    padding: 3rem;
}

.p4 {
    padding: 4rem;
}

.p5 {
    padding: 5rem;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.pt-1 {
    padding-top: 1rem;
}

.pt-2 {
    padding-top: 2rem;
}

.pt-3 {
    padding-top: 3rem;
}

.pt-4 {
    padding-top: 4rem;
}

.pt-5 {
    padding-top: 5rem;
}

.pb-1 {
    padding-bottom: 1rem;
}

.pb-2 {
    padding-bottom: 2rem;
}

.pb-3 {
    padding-bottom: 3rem;
}

.pb-4 {
    padding-bottom: 4rem;
}

.pb-5 {
    padding-bottom: 5rem;
}

.pr-1 {
    padding-right: 1rem;
}

.pr-2 {
    padding-right: 2rem;
}

.pr-3 {
    padding-right: 3rem;
}

.pr-4 {
    padding-right: 4rem;
}

.pr-5 {
    padding-right: 5rem;
}

.pl-1 {
    padding-left: 1rem;
}

.pl-2 {
    padding-left: 2rem;
}

.pl-3 {
    padding-left: 3rem;
}

.pl-4 {
    padding-left: 4rem;
}

.pl-5 {
    padding-left: 5rem;
}

.m1 {
    margin: 1rem;
}

.m2 {
    margin: 2rem;
}

.m3 {
    margin: 3rem;
}

.m4 {
    margin: 4rem;
}

.m5 {
    margin: 5rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mt-4 {
    margin-top: 4rem;
}

.mt-5 {
    margin-top: 5rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-4 {
    margin-bottom: 4rem;
}

.mb-5 {
    margin-bottom: 5rem;
}

.mb-6 {
    margin-bottom: 6rem;
}

.mr-1 {
    margin-right: 1rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mr-3 {
    margin-right: 3rem;
}

.mr-4 {
    margin-right: 4rem;
}

.mr-5 {
    margin-right: 5rem;
}

.ml-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.ml-3 {
    margin-left: 3rem;
}

.ml-4 {
    margin-left: 4rem;
}

.ml-5 {
    margin-left: 5rem;
}

// Utilidades para textos
.underline {
    text-decoration: underline;
}

.bold {
    font-weight: bolder;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

// Centra el texto
.text-center {
    text-align: center;
}

// Fonts
.font__roboto {
    font-family: "Roboto", sans-serif !important;
}

.font__commutter {
    font-family: "CommutersSans", sans-serif !important;
}

// Override Botones
.MuiButton-containedPrimary {
    .MuiButton-label {
        color: #fff;
    }
}

.MuiIconButton-colorSecondary {
    background-color: #000 !important;
    color: #fff;
}

.MuiPickersCalendarHeader-dayLabel,
.MuiPickersCalendarHeader-transitionContainer>.MuiTypography-body1 {
    color: #000 !important;
    font-family: "CommutersSans" !important;
    text-transform: uppercase !important;
}

.MuiPickersTimePickerToolbar-ampmLabel.MuiPickersToolbarText-toolbarBtnSelected {
    background-color: #000 !important;
    color: #fff !important;
}

.MuiTypography-h2.MuiPickersToolbarText-toolbarTxt {
    font-size: 2.5rem;
}

.MuiAlert-filledSuccess {
    background-color: #3d990f !important;
}

.MuiAlert-filledError {
    background-color: #cb141d !important;
}

.MuiRating-root {
    font-size: 1rem !important;
}

// Message Colors
.color-error {
    color: #cb141d;
}

.color-success {
    color: #2b8000;
}

.color-grey {
    color: rgba(0, 0, 0, 0.4);
}

// Colores para mostrar disponibilidad
.color-full-avaliability {
    color: #2b8000;
}

.color-mid-avaliability {
    color: #ed8123;
}

.color-no-avaliability {
    color: #cb141d;
}

// Estilo para el modal sobre toda la app
.ReactModalPortal {
    position: absolute;
    top: 0;
}

// Estilo para Botón de busqueda el lado de los títulos de layouts
.search-icon__button {
    margin-right: .8rem !important;
}

// Estilo forzar el color azul oscuro en la MiuAlert de notificaciones push
.MuiAlert-filledInfo {
    background-color: #184371 !important;
}