.reservationsCreateForm {
  .MuiFormGroup-root {
    flex-direction: row;
  }

  .MuiFormControl-root {
    width: 100%;
  }
}

.reservationsCreateForm__button-group {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.reservationsCreateForm__lastNames-group {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  .MuiTextField-root {
    width: 50%;
  }
}

.bold {
  font-weight: bold !important;
}

.reservationsCreateForm__lastNames-group .MuiTextField-root:nth-child(2) {
  padding-left: .5rem;
}

.reservationsCreateForm__phone-group {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  .MuiTextField-root {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .reservationsCreateForm {
    .MuiFormGroup-root {
      flex-direction: column;
    }
  }

  .reservationsCreateForm__lastNames-group {
    flex-direction: column;

    .MuiTextField-root {
      width: 100%;
    }
  }

  .reservationsCreateForm__lastNames-group .MuiTextField-root:nth-child(2) {
    margin-top: 1rem;
    padding-left: 0;
  }

  .reservationsCreateForm__phone-group {
    .MuiTextField-root {
      width: 100%;
    }
  }
}

.redy>* {
  color: #FB1010 !important;
}

.event>* {
  color: #1028fb !important;
  font-weight: bold !important;
}

.react-autosuggest__input:focus-visible{

  border: 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.42);
  outline: none;

}
.react-autosuggest__input {
  animation-duration: 10ms;
  animation-name: mui-auto-fill-cancel;

  background: none;
  border: 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.42);
  box-sizing: content-box;

  color: currentColor;
  display: block;
  font: inherit;
  font-size: 1rem;
  height: 1.1876em;
  letter-spacing: inherit;


  margin: 0;
  margin-bottom: 1rem;

  /* padding: 6px 0 7px; */
  min-width: 0;
  padding: 6px 0 7px;

  -webkit-tap-highlight-color: transparent;
  width: 100%;

}
.sugerencia{
  background-color: #000;
  color: #fff;
  padding: 10px 5px;
}
.react-autosuggest__suggestions-container {
  background-color: #000;
}
.textRed{
  color: #FB1010 !important;
}