.image-thumbnail {
    cursor: pointer;
    height: 100%;
    position: relative;
    width: 100%;
}

.image-thumbnail .image-thumbnail-mask {
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    height: inherit;
    position: absolute;
    width: 100%;
    z-index: 0;
}

.image-thumbnail:hover .image-thumbnail-mask {
    background-color: rgba(0, 0, 0, .65);
    z-index: 10;
}

.image-thumbnail .image-thumbnail-mask .image-thumbnail__delete-icon {
    color: rgba(255, 255, 255, 0);
    position: absolute !important;
    right: 0;
    top: 0;
}

.image-thumbnail:hover .image-thumbnail-mask .image-thumbnail__delete-icon {
    color: rgba(255, 255, 255, 1);
}

.image-thumbnail__delete-icon.MuiIconButton-colorSecondary {
    background-color: rgba(0, 0, 0, 0) !important;
}

@media only screen and (max-width: 600px) {
    .image-thumbnail:hover .image-thumbnail-mask {
        background-color: rgba(0, 0, 0, .65);
        transition-delay: 2s;
        z-index: 10;
    }
}