.floormap__tabs-box {
    border-bottom: solid 0.5px rgba(0, 0, 0, .2);
    padding: 1.2rem 0 0;
}

@media only screen and (max-width: 600px) {
    .reservations__tabs-box {
        padding: 0;
    }
}

.spinner-loader {
    animation: rotation 1s linear infinite;
    border: 10px solid #050505;
    border-bottom-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    height: 70px;
    width: 70px;
}

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

.spinner-overlay{
    align-items: center;
    background-color: rgb(116 115 115 / 27%);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: 0.9s ease;
    z-index: 11100;
}
