.reservations-accordion__container {
    margin: .5rem .1rem;
    max-width: 100%;
    width: 100%;
}

.reservations-accordion__header {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 36px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    .reservations-accordion__name {
        font-family: "CommutersSans";
        text-transform: uppercase;
    }
}

.reservations-d__flex {
    display: flex;
}

.contacttext {
    font-family: "CommutersSans" !important;
    font-size: 13.3px;
    margin-right: 10px;
    white-space:nowrap;
}

.contacticon {
    margin-left: 5px;
    width: 20px;
}

.iconcake {
    height: 25px;
    width: 25px;
}

.cakespan {
    display: flex;
    vertical-align: center;
}

.cakeText {
    padding-top: 5px;
}

.reservations-accordion__expansion {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.reservations-accordion__expansion-comments {
    line-break: anywhere;
    width: 85%;
}

.reservations-accordion__expansion-button {
    width: 10%;
}

.reservations-accordion__categories {
    flex-wrap: wrap;
}

.MuiAccordionSummary-root {
    padding-left: 16px !important;

}

.MuiAccordionSummary-content {
    margin: 12px 10px;
}

.capitalized {
    text-transform: capitalize !important;
}

.separator {
    display: block;
    padding: 15px 10px;
    width: 100%;
}
.guion{
    font-family: "Roboto";
    font-weight: 100;
}
.photocontainer {
    border: solid 1px #bfbfbf;
    border-radius: 50px 50px 1px 1px;
    margin-right: 15px;
    max-height: 150px;
    padding: 4px;
    width: 100px;
}

.photocontainer>* {
    background-size: cover;
    border-radius: 50px 50px 1px 1px;
    height: 100%;
    max-height: 125px;

}
.reservations-accordion__history {
    margin-left: 10px !important;
}