.restaurantMenu-form__field-container {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .restaurantMenu-form__field-container {
        max-width: 300px;
    }
}

.border-bottom-lite{
    border-bottom: 1px #d1c9c9 solid !important;
}

.card-menu{
    border: .0625rem solid #e5e5e5;
    margin-bottom: 16px;
    padding: 1.5rem;
}