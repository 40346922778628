.staff-accordion__container {
    margin: .5rem .1rem;
    max-width: 100% !important;
    width: 100%;
}

.staff-accordion__summary {
    display: flex;
    flex-direction: row;
}

.staff-accordion__info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin: .5rem 0;
    width: 75%;
    .staff-accordion__name {
        flex-wrap: wrap;
        font-family: "CommutersSans";
        line-break: anywhere;
        text-transform: uppercase;
    }
    .staff-accordion__categories {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.staff-accordion__expansion {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}