.reservationsCreateForm {
    .MuiFormGroup-root {
        flex-direction: row;
    }
}

.reservationsCreateForm__button-group {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.reservationsCreateForm__lastNames-group {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    .MuiTextField-root {
        width: 50%;
    }
}

.reservationsCreateForm__lastNames-group .MuiTextField-root:nth-child(2) {
    padding-left: .5rem;
}

.reservationsCreateForm__phone-group {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    .MuiTextField-root {
        width: 50%;
    }
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 600px) {
    .reservationsCreateForm {
        .MuiFormGroup-root {
            flex-direction: column;
        }
    }
    .reservationsCreateForm__lastNames-group {
        flex-direction: column;
        .MuiTextField-root {
            width: 100%;
        }
    }
    .reservationsCreateForm__lastNames-group .MuiTextField-root:nth-child(2) {
        margin-top: 1rem;
        padding-left: 0;
    }
    .reservationsCreateForm__phone-group {
        .MuiTextField-root {
            width: 100%;
        }
    }
}
.marginTop20{
    margin-top: 20px;
}
.disclaimer{
        font-family: CommutersSans;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 20px;
        text-transform: uppercase;
  }
  .resaltado{
      color:#ff4d4f !important;
  }