.table-error {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
  }
.error-icon {
      max-width: 120px;
  }
.Este-da-ha-sido-bloqueado-POR {
      color: var(--black);
      font-family: CommutersSans;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 1.6px;
      line-height: normal;
      margin: 27px 35px 24.6px 26px;
      text-align: center;
      text-transform: uppercase;
  }
.tableErrorTitle {
        font-family: Roboto;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 300;
        letter-spacing: 0.35px;
        line-height: 1;
        margin: 15.6px 45px;
        text-align: center;
    }

  .message2{
      font-family: Roboto;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 300;
      letter-spacing: 0.35px;
      line-height: 1;
      margin: 15.6px 45px;
      text-align: center;
  }
    