.number-increments__buttonGroup {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .MuiButton-contained.Mui-disabled {
        background-color: #fff !important;
    }
}

.number-increments {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.number-increments__number.is-disabled {
    opacity: .4;
}

.number-increments__number {
    border: 1px solid #000;
    font-size: 2rem;
    font-weight: bold;
    padding: .3rem;
}