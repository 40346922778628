.restaurant {
    display: flex;
    flex-direction: column;
    position: relative;
}

.restaurant__header {
    padding-top: 2rem;
}

.restaurant__tabs-container {
    max-width: 100%;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .restaurant__header {
        border-bottom: .3rem solid #000;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    .restaurant__tabs-container {
        margin-top: 0 !important;
        padding: 1rem 0 !important;
    }
}
