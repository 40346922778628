.charges-accordion__container {
    margin: .5rem .1rem;
    max-width: 100% !important;
    width: 100%;
}

.charges-accordion__summary {
    display: flex;
    flex-direction: row;
}

.charges-accordion__picture {
    width: 25%;
}

.charges-accordion__info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: 75%;
    .charges-accordion__name {
        flex-wrap: wrap;
        font-family: "CommutersSans";
        text-transform: uppercase;
    }
    .charges-accordion__categories {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}