.menu-status-button,
{
    align-items: center;
    background-color: #fff;
    border: 1px solid;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    font-family: "CommutersSans" !important;
    justify-content: center;
}

.menu-status-options__menu-item,
{
    background-color: #fff !important;
    border: 1px solid !important;
    border-radius: 25px !important;
    font-family: "CommutersSans" !important;
    justify-content: center !important;
    margin: .3rem !important;
    padding: 0 4px !important;
}
.menu-status-button.enespera,
.menu-status-options__menu-item.enespera {
    border-color: #757575;
    color: #757575;
    white-space: nowrap;
}

.menu-status-button.haasistido,
.menu-status-options__menu-item.haasistido {
    border-color: #757575;
    color: #757575;
    white-space: nowrap;
}

.menu-status-button.noshow,
.menu-status-options__menu-item.noshow {
    border-color: #c87e20;
    color: #c87e20;
    white-space: nowrap;
}
.sinrespuesta{
    border-color: #c87e20;
    color: #c87e20;
    white-space: nowrap;
}
.menu-status-button.sehaido,
.menu-status-options__menu-item.sehaido {
    border-color: #187616;
    color: #187616;
    white-space: nowrap;
}

.menu-status-button.cancelado,
.menu-status-options__menu-item.cancelado {
    border-color: #cb141d;
    color: #cb141d;
    white-space: nowrap;
}
.menu-status-button.eliminado,
.menu-status-options__menu-item.eliminado,
.eliminado {
    border-color: #a210cf;
    color: #a210cf;
    white-space: nowrap;
}
.aceptado {
    border-color: #187616;
    color: #187616;
    white-space: nowrap;
}
.rechazado
 {
    border-color: #cb141d;
    color: #cb141d;
    white-space: nowrap;
}
.sinrespuesta{
    border-color: #c87e20;
    color: #c87e20;
    white-space: nowrap;
}
.listadeespera{
    border-color: #db009a !important;
    color: #db009a !important;
    white-space: nowrap;
}
.pendienteporcontestar{
    border-color: #db009a !important;
    color: #db009a !important;
    white-space: nowrap;
}
.rechazado{
    border-color: #B80103 !important;
    color: #B80103 !important;
    white-space: nowrap;
}