.reservationsCreateForm {
  .MuiFormGroup-root {
    flex-direction: row;
}
.MuiFormControl-root {
  width: 100%;
}
}
.reservationsCreateForm__button-group {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.reservationsCreateForm__lastNames-group {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  .MuiTextField-root {
    width: 50%;
  }
}

.reservationsCreateForm__lastNames-group .MuiTextField-root:nth-child(2) {
  padding-left: .5rem;
}

.reservationsCreateForm__phone-group {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  .MuiTextField-root {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .reservationsCreateForm {
  .MuiFormGroup-root {
    flex-direction: column;
    }
  }
  .reservationsCreateForm__lastNames-group {
    flex-direction: column;
    .MuiTextField-root {
      width: 100%;
    }
  }
  .reservationsCreateForm__lastNames-group .MuiTextField-root:nth-child(2) {
    margin-top: 1rem;
    padding-left: 0;
  }

  .reservationsCreateForm__phone-group {
  .MuiTextField-root {
    width: 100%;
  }
}
}
.hiden{
  display: none !important;
}