.restaurantDescription__field-group {
    align-items: center;
    border-bottom: solid 0.5px rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    width: 100%;
}

.restaurantDescription-form__field-container {
    margin-right: 1rem;
    width: 100%;

    .color-grey {
        padding-bottom: 1rem;
    }
}
.cajagris{
    margin-left: 15px;
    margin-right: 15px;
}
.warning {
      color: rgba(235, 0, 0, 0.76);
      font-family: roboto;
      font-size: 14px;
      padding-left:15px;
      padding-right:15px;
  }

.restaurantImages__group {
    position: relative;
}

.restaurantImages__dropzone-container {
    background-color: rgba(0, 0, 0, 0.2);
}

.justified {
    text-align: justify;
}

.restaurantImages__cta-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2rem;
}

.btnHolder {
      display: flex;
      justify-content: end;
      margin-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
  }

.restaurantImages__dropzone {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-family: Roboto;
    height: inherit;
    justify-content: center;
    padding: 0 1em;

    .dropzone {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: inherit;
        justify-content: center;
    }
}

.restaurant-image__item {
    .MuiImageListItem-item {
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

.restaurantImages__files-container {
    margin-bottom: 1em;
}

.restaurantImages-left-grid,
.restaurantImages-right-grid {
    position: relative;
}

.restaurantImages-right-grid::before {
    border: .7px solid rgba(0, 0, 0, .06);
    content: "";
    height: 100%;
    left: -1.5rem;
    position: absolute;
    top: 0;
    width: 0;
}

.restaurantCover__files-container {
    padding-left: 15px;
    padding-right: 15px;
}

@media only screen and (max-width: 600px) {
    .restaurantCover__files-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .restaurantDescription__field-group {
        justify-content: start;
    }

    .restaurantDescription-form__field-container {
        padding-right: .5rem;
        width: 65%;
    }

    .restaurantDescription-form__buttonGroup {
        width: 30%;
    }

    .restaurantImages-right-grid {
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 2rem;
    }

    .restaurantImages__right-grid::before {
        border: 0;
        content: "";
        height: 0;
    }
}