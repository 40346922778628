.Offers {
    display: flex;
    flex-direction: column;
    position: relative;
}

.Offers__header {
    border-bottom: .05rem solid rgba(0, 0, 0, .1);
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.Offers__tabs-container {
    max-width: 100%;
    width: 100%;
}

.Offers-table__box {
    max-width: 100%;
    overflow-x: scroll;
}

.Offers__searchBar {
    background-color: #fff;
    padding: .2rem .5rem;
}

.Offers-table__box {
    overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
    .Offers__header {
        border-bottom: .3rem solid #000;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    .Offers__tabs-container {
        margin-top: 0 !important;
        padding: 1rem 0 !important;
    }
}
.offers__header{
    margin-top:80px ;
}