.reservations-calendar {
  .MuiInput-input {
    display: none !important;
  }
}

.hasReservations {
  position: relative;
}

.hasReservations::after {
  bottom: 0;
  color: #cb141d;
  content: ".";
  font-weight: bold;
  margin: 0 auto;
  position: absolute;
}

.leyenda {

  border-radius: 50%;
  display:block;
  height: 10px;
  min-width: 10px;
  width: 1px;



}
.leyendarow{


  display: flex;
  font-size: 11px !important;

  margin-bottom: 5px;

  max-width: 320px !important;
  padding-left: 15px;
  padding-right: 15px;
}
 .leyendarow > *:first-child {
  margin-top: 2px ;
} 
.leyendarow > * {
  margin-left:5px;

}
.rojo {
  background: #cb141d;
}
.verde {
  background: #00a650;
}
.naranja  {
  background: #f7941d;
}
.blanco  {
  background: #fffefd;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
}
.MuiPickersBasePicker-container {
  display: flex;
  flex-direction: column-reverse !important;
}
.azuloscuro {
  background: #0b5394;
}
.oscuro {
  background: #000408;
 
  right: 14px !important;
  
}
.morado {
  background: #9800c2;
}

.MuiBadge-colorThirdary {
  background-color: #000408 !important;
  color:  #000 !important;

}
.Thirdary {
  background-color: #000408 !important;
  color:  #000 !important;

}
.MuiBadge-colorBlack {
  background-color: rgb(0, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
}
.Black {
  background-color: rgb(0, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
}